<template>
  <div class="container-modal-info">
    <img
      src="@/assets/icons/close-modal.svg"
      class="close-btn"
      @click="
        $emit('closeModal', false)
        counter = 0
      "
    />
    <div
      class="container-header-notification"
      :style="{ 'background-color': infoNotification.background_header }"
    >
      <h3
        class="title-header-notification"
        v-html="infoNotification.titulo"
      ></h3>
      <p
        class="text-header-notification"
        v-html="infoNotification.subtitulo"
      ></p>
    </div>
    <div
      class="container-body"
      :style="{ 'background-color': infoNotification.background_body }"
    >
      <p class="text-center mb-2"><b>Usa el siguiente código</b></p>
      <div class="container-copy-code">
        <p id="copy-cod" class="text-copy-code col">
          {{ infoNotification.cupon_codigo }}
        </p>
        <button
          class="btn-copy-code"
          :style="copiedCode ? 'background-color: #c6ecbb' : ''"
          @click="!copiedCode ? copiarTexto() : ''"
        >
          {{ copiedCode ? 'Copiado!' : 'Copiar' }}
        </button>
      </div>
      <button
        style="width: 250px"
        class="bnt-primary mx-auto d-block px-5 mb-3"
        @click="$router.push({ name: 'AllCategory' })"
      >
        Comprar ahora
      </button>
      <div class="mb-3">
        <p
          id="text-regresive-count"
          class="text-center"
          style="font-weight: bold"
        ></p>
      </div>
      <router-link
        class="d-block fs-14 text-left"
        style="color: black"
        :to="{}"
      >
        *Aplican TyC
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    infoNotification: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      copiedCode: false,
      counter: 30,
    }
  },
  mounted() {
    this.startTimer(this.infoNotification.conteo_regresivo)
  },
  methods: {
    async startTimer(time) {
      let textClose = document.getElementById('text-regresive-count')
      this.counter = time
      if (time > 0 && this.counter > 0) {
        if (textClose) {
          textClose.innerHTML = 'No lo dejes pasar: ' + time.toString()
        }
        await setTimeout(() => {
          if (this.counter > 0) {
            this.startTimer(time - 1)
          }
        }, 1000)
      } else {
        this.$emit('closeModal', false)
        this.counter = 0
      }
    },
    async copiarTexto() {
      var aux = document.createElement('input')
      aux.setAttribute('value', document.getElementById('copy-cod').innerText)
      document.body.appendChild(aux)
      aux.select()
      document.execCommand('copy')
      document.body.removeChild(aux)
      this.copiedCode = true
      await setTimeout(() => {
        this.copiedCode = false
      }, 4000)
    },
  },
}
</script>
