<template>
  <div id="payment" class="pt-4">
    <div
      v-if="dataPayment && dataPayment.valor_total"
      style="background-color: white"
      class="col-11 col-md-10 mx-auto mt-3"
    >
      <div class="container-resumen-transaccion">
        <div
          v-if="dataPayment.status_id == 1"
          class="mx-auto mb-4 d-flex flex-wrap"
        >
          <div class="estado-transaccion" style="background-color: #c6ecbb">
            <img src="@/assets/imgs/estado-success.svg" width="33" />
          </div>
          <h3 class="w-100" style="text-align: center">
            <b>Transacción exitosa</b>
          </h3>
          <p class="w-100" style="text-align: center">
            Se ha registrado tu pago
          </p>
        </div>
        <div
          v-if="dataPayment.status_id == 2"
          class="mx-auto mb-4 d-flex flex-wrap"
        >
          <div class="estado-transaccion" style="background-color: #ffc8c8">
            <img src="@/assets/imgs/estado-error.svg" width="33" />
          </div>
          <h3 class="w-100" style="text-align: center">
            <b>Transacción rechazada</b>
          </h3>
          <p class="w-100" style="text-align: center">
            No se ha podido validar tu pago
          </p>
        </div>
        <div
          v-if="dataPayment.status_id == 0"
          class="mx-auto mb-4 d-flex flex-wrap"
        >
          <div class="estado-transaccion" style="background-color: #ffe7cb">
            <img src="@/assets/imgs/estado-alert.svg" width="33" />
          </div>
          <h3 class="w-100" style="text-align: center">
            <b>Transacción Pendiente</b>
          </h3>
          <p class="w-100" style="text-align: center">
            La transacción esta en proceso
          </p>
        </div>

        <div class="w-100 d-flex flex-wrap">
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Referencia de la venta</b> <br />
              {{ dataPayment.referencia }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Referencia de la transacción</b> <br />
              {{ dataPayment.transaccion_id }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Fecha</b> <br />
              {{ dataPayment.fecha }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Valor total</b> <br />
              {{ dataPayment.valor_total }} {{ dataPayment.moneda }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Descripción</b> <br />
              {{ dataPayment.descripcion }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Entidad</b> <br />
              {{ dataPayment.entidad }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <notification-summary-payment
      v-if="showmodal"
      :info-notification="infoNotification"
      @closeModal="showmodal = false"
    />
  </div>
</template>
<script>
import NotificationSummaryPayment from '@/components/Templates/NotificationSummaryPayment'
export default {
  name: 'PaymentEmail',
  components: { NotificationSummaryPayment },
  data() {
    return {
      dataPayment: null,
      infoNotification: null,
      showmodal: false,
    }
  },
  mounted() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover-simple'
    this.$store.state.layouts.menuLayoutClover = 'menu-layout-simple'
  },
  beforeDestroy() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover'
    this.$store.state.layouts.menuLayoutClover = 'menu-clover'
  },
  created() {
    let query = this.$route.fullPath.split('?')[1]
      ? this.$route.fullPath.split('?')[1]
      : ''
    if (query != '') {
      this.$store.state.loaderClover = true
      this.axios({
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/apiBancolombia/response?' + query,
      })
        .then(response => {
          this.dataPayment = response.data
          this.$store.state.loaderClover = false
          this.infoNotification = response.data.cuponEstrategiaReventa
          this.showmodal = true
        })
        .catch(() => {
          this.$store.state.loaderClover = false
          this.$router.push({ name: 'Home' })
        })
    }
    // this.$gtag.pageview(this.$route.fullPath);
  },
}
</script>
<style lang="scss">
.container-resumen-transaccion {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  padding-top: 0;
  margin-top: 4rem;
  margin-right: auto;
  margin-left: auto;
  border-radius: 5px;
}
.container-resumen-transaccion .estado-transaccion {
  border-radius: 50%;
  min-height: 70px;
  min-width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: -35px;
  margin-bottom: 1rem;
}
.container-resumen-transaccion .item-transaccion {
  padding: 0.5rem;
}
.container-resumen-transaccion .item-transaccion p {
  width: 100%;
  background-color: #f7f3f0;
  margin-bottom: 0;
  text-align: center;
  padding: 1.5rem 1rem;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .container-resumen-transaccion {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
